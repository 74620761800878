<template>
	<header class="header">
        <div class="wrapper">
          <div class="left">
            <router-link to="/" class="logo">
              <img src="./../assets/logo.svg" class="img"/>
              <img src="./../assets/logo-text.svg" class="img"/>
            </router-link>
            <ul class="nav">
              <li class="nav__item">
                <a @click="scrollToSection('about')">
                  <span class="desc">About</span>
                </a>
              </li>
              <li class="nav__item">
                <a @click="scrollToSection('memefi')">
                  <span class="desc">MemeFi</span>
                </a>
              </li>
              <li class="nav__item">
                <a @click="scrollToSection('think')">
                  <span class="desc">Sinners NFTs</span>
                </a>
              </li>
              <li class="nav__item">
                <a @click="scrollToSection('tokensmics')">
                  <span class="desc">Tokenomics</span>
                </a>
              </li>
              <!-- <li class="nav__item">
                <a @click="scrollToSection('roadmap')">
                  <span class="desc">Roadmap</span>
                </a>
              </li> -->
            </ul>
          </div>
          <div class="right">
            <div class="socials-list">
              <div class="item">
                <a href="https://t.me/thesindao" target="_blank">
                  <img src="./../assets/socials/telegram.svg" class="img"/>
                </a>
              </div>
              <div class="item">
                <a href="https://twitter.com/thesinDAO" target="_blank">
                  <img src="./../assets/socials/twitter.svg" class="img"/>
                </a>
              </div>
              <div class="item">
                <a href="https://discord.gg/thesindao" target="_blank">
                  <img src="./../assets/socials/discord.svg" class="img"/>
                </a>
              </div>
            </div>
            <a target="_blank" href="https://app.sindao.org" class="button">
              Launch App
            </a>
          </div>
        </div>
    </header>
</template>

<script>

export default {
	name: 'Header',
  props: [],
	data: function() {
		return {
		}
	},
	methods: {
    scrollToSection(section) {
      this.$emit('scrollToSection', section)
    },
	}
}
</script>
