var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer"},[_c('div',{staticClass:"footer-section"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"left"},[_c('ul',{staticClass:"nav"},[_vm._m(0),_c('li',{staticClass:"nav__item"},[_c('a',{staticClass:"desc",on:{"click":function($event){return _vm.scrollToSection('about')}}},[_vm._v(" About ")])]),_c('li',{staticClass:"nav__item"},[_c('a',{staticClass:"desc",on:{"click":function($event){return _vm.scrollToSection('memefi')}}},[_vm._v(" MemeFi ")])]),_c('li',{staticClass:"nav__item"},[_c('a',{staticClass:"desc",on:{"click":function($event){return _vm.scrollToSection('tokensmics')}}},[_vm._v(" Tokenomics ")])]),_vm._m(1)]),_vm._m(2),_vm._m(3)]),_vm._m(4)])]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav__item"},[_c('div',{staticClass:"title"},[_vm._v(" sinDAO: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav__item"},[_c('a',{staticClass:"desc",attrs:{"target":"_blank","href":"https://www.coingecko.com/en/coins/sin"}},[_vm._v(" CoinGecko ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"nav small"},[_c('li',{staticClass:"nav__item"},[_c('div',{staticClass:"title"},[_vm._v(" $SIN Token: ")])]),_c('li',{staticClass:"nav__item"},[_c('a',{staticClass:"desc",attrs:{"target":"_blank","href":"https://birdeye.so/token/sin1uRe1cMCWR7VPLdZrGrvKs8UvKMsGzhvpJLg4Ld9?chain=solana"}},[_vm._v(" Birdeye ")])]),_c('li',{staticClass:"nav__item"},[_c('a',{staticClass:"desc",attrs:{"target":"_blank","href":"https://www.dextools.io/app/en/solana/pair-explorer/DZ84nE6VjMLcn2hWEu9hHTU4sPewkborxeHH8QMjb5ie"}},[_vm._v(" DEXTools ")])]),_c('li',{staticClass:"nav__item"},[_c('a',{staticClass:"desc",attrs:{"target":"_blank","href":"https://jup.ag/swap/wSOL-sin1uRe1cMCWR7VPLdZrGrvKs8UvKMsGzhvpJLg4Ld9"}},[_vm._v(" Jupiter ")])]),_c('li',{staticClass:"nav__item"},[_c('a',{staticClass:"desc",attrs:{"target":"_blank","href":" https://solscan.io/token/sin1uRe1cMCWR7VPLdZrGrvKs8UvKMsGzhvpJLg4Ld9"}},[_vm._v(" Contract ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"nav small"},[_c('li',{staticClass:"nav__item"},[_c('div',{staticClass:"title"},[_vm._v(" Sinner NFTs: ")])]),_c('li',{staticClass:"nav__item"},[_c('a',{staticClass:"desc",attrs:{"target":"_blank","href":"https://www.tensor.trade/trade/sindao"}},[_vm._v(" Tensor ")])]),_c('li',{staticClass:"nav__item"},[_c('a',{staticClass:"desc",attrs:{"target":"_blank","href":"https://magiceden.io/marketplace/sindao"}},[_vm._v(" Magic Eden ")])]),_c('li',{staticClass:"nav__item"},[_c('a',{staticClass:"desc",attrs:{"target":"_blank","href":"https://solanart.io/collections/sinner"}},[_vm._v(" Solanart ")])]),_c('li',{staticClass:"nav__item"},[_c('a',{staticClass:"desc",attrs:{"target":"_blank","href":"https://www.sniper.xyz/collection/sindao"}},[_vm._v(" Sniper ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('ul',{staticClass:"nav small"},[_c('li',{staticClass:"nav__item"},[_c('div',{staticClass:"title"},[_vm._v(" Community: ")])]),_c('li',{staticClass:"nav__item"},[_c('a',{staticClass:"desc",attrs:{"href":"https://twitter.com/thesinDAO","target":"_blank"}},[_vm._v(" X (Twitter) ")])]),_c('li',{staticClass:"nav__item"},[_c('a',{staticClass:"desc",attrs:{"href":"https://t.me/thesindao","target":"_blank"}},[_vm._v(" Telegram ")])]),_c('li',{staticClass:"nav__item"},[_c('a',{staticClass:"desc",attrs:{"href":"https://discord.gg/thesindao","target":"_blank"}},[_vm._v(" Discord ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"copyright-section"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"desc"},[_vm._v(" $SIN is a meme coin with no intrinsic value or expectation of financial return. There is no formal team or roadmap."),_c('br'),_vm._v(" The $SIN token is used to mint NFT art or for entertainment purposes only. ")]),_c('div',{staticClass:"title"},[_vm._v("© 2666 sinDAO. All Rights Reserved.")])])])
}]

export { render, staticRenderFns }