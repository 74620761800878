import Vue from 'vue'
import VueRouter from 'vue-router'
import PageNotFound from './pages/PageNotFound.vue'
import Home from './pages/Home.vue'


Vue.use(VueRouter)

export default new VueRouter({
	mode: 'history',
	routes: [
		{
			path: '/',
			name: 'Home',
			component: Home
		},
		{ 
			path: "*", 
			name: 'PageNotFound',
			component: PageNotFound,
			props: {title: "PageNotFound"}
		}
	]
})

