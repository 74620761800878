<template>
  <div id="app" :class="['app ' + $route.name]">
    <transition name="fade">
      <div id="page" class="page" v-if="pageLoaded">
        <Header @scrollToSection="scrollToSection" />
        <router-view @scrollToSection="scrollToSection" />
        <Footer @scrollToSection="scrollToSection" />
      </div>
    </transition>
  </div>
</template>

<script>
import {scroller} from 'vue-scrollto/src/scrollTo'
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'


export default {
  name: 'app',
  components: {
    Header,
    Footer,
  },
  data: function() {
    return {
      pageLoaded: false
    }
  },
  mounted() {
    this.pageLoaded = true;
  },
  methods: {
    scrollToSection(section) {
      if (this.$route.name != 'Home') {
        this.$router.push({path: '/'});
      }
      var options = {
          offset: 0
      }
      const firstScrollTo = scroller()
      firstScrollTo('#' + section, 1000, options)
    },
  },
  
  
}
</script>
