<template>
	<footer class="footer">
    <div class="footer-section">
      <div class="wrapper">
        <div class="left">
          <ul class="nav">
            <li class="nav__item">
              <div class="title">
                sinDAO:
              </div>
            </li>
            <li class="nav__item">
              <a @click="scrollToSection('about')" class="desc">
                About
              </a>
            </li>
            <li class="nav__item">
              <a @click="scrollToSection('memefi')" class="desc">
                MemeFi
              </a>
            </li>
            <li class="nav__item">
              <a @click="scrollToSection('tokensmics')" class="desc">
                Tokenomics
              </a>
            </li>
            <li class="nav__item">
              <a target="_blank" href="https://www.coingecko.com/en/coins/sin" class="desc">
                CoinGecko
              </a>
            </li>
          </ul>
          <ul class="nav small">
            <li class="nav__item">
              <div class="title">
                $SIN Token:
              </div>
            </li>
            <li class="nav__item">
              <a target="_blank" href="https://birdeye.so/token/sin1uRe1cMCWR7VPLdZrGrvKs8UvKMsGzhvpJLg4Ld9?chain=solana" class="desc">
                Birdeye
              </a>
            </li>
            <li class="nav__item">
              <a target="_blank" href="https://www.dextools.io/app/en/solana/pair-explorer/DZ84nE6VjMLcn2hWEu9hHTU4sPewkborxeHH8QMjb5ie" class="desc">
                DEXTools
              </a>
            </li>
            <li class="nav__item">
              <a target="_blank" href="https://jup.ag/swap/wSOL-sin1uRe1cMCWR7VPLdZrGrvKs8UvKMsGzhvpJLg4Ld9" class="desc">
                Jupiter
              </a>
            </li>
            <li class="nav__item">
              <a target="_blank" href=" https://solscan.io/token/sin1uRe1cMCWR7VPLdZrGrvKs8UvKMsGzhvpJLg4Ld9" class="desc">
                Contract  
              </a>
            </li>
          </ul>
          <ul class="nav small">
            <li class="nav__item">
              <div class="title">
                Sinner NFTs:
              </div>
            </li>
            <li class="nav__item">
              <a target="_blank" href="https://www.tensor.trade/trade/sindao" class="desc">
                Tensor
              </a>
            </li>
            <li class="nav__item">
              <a target="_blank" href="https://magiceden.io/marketplace/sindao" class="desc">
                Magic Eden 
              </a>
            </li>
            <li class="nav__item">
              <a target="_blank" href="https://solanart.io/collections/sinner" class="desc">
                Solanart  
              </a>
            </li>
            <li class="nav__item">
              <a target="_blank" href="https://www.sniper.xyz/collection/sindao" class="desc">
                Sniper  
              </a>
            </li>
          </ul>
        </div>
        <div class="right">
          <ul class="nav small">
            <li class="nav__item">
              <div class="title">
                Community:
              </div>
            </li>
            <li class="nav__item">
              <a href="https://twitter.com/thesinDAO" target="_blank" class="desc">
                X (Twitter)
              </a>
            </li>
            <li class="nav__item">
              <a href="https://t.me/thesindao" target="_blank" class="desc">
                Telegram
              </a>
            </li>
            <li class="nav__item">
              <a href="https://discord.gg/thesindao" target="_blank" class="desc">
                Discord  
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="copyright-section">
      <div class="wrapper">
        <div class="desc">
          $SIN is a meme coin with no intrinsic value or expectation of financial return. There is no formal team or roadmap.<br/> The $SIN token is used to mint NFT art or for entertainment purposes only. 
        </div>
        <div class="title">© 2666 sinDAO. All Rights Reserved.</div>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
	name: 'Footer',
	props: [],
	data: function() {
		return {
      textPageList: []
		}
	},
	methods: {
		scrollToSection(section) {
      this.$emit('scrollToSection', section)
    },
	},
	mounted() {
    
  }
}
</script>
